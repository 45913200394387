<template>
    <div class="ml-2 mr-2">
        <div class="pb-2 h-100 ">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToListView">Employee </span>
                        <div>Add New</div>
                    </div>
                </div>
            </div>
            <div class="start-overflow">
                <div class="main-div create-company-container w-100">
                    <div class="main-div">
                        <div class="row">
                            <div class="col-lg-12 px-0">
                                <div class="pb-6 px-0 mb-0">
                                    <div class="text-center fs-14 fw-600" style="color: #003cb9">
                                        ADD New Employee
                                    </div>
                                    <div class="d-flex justify-content-center align-items-center w-100 mt-6">
                                        <span :class="basicInfo ? 'selectedCircle' : permissionProduct  ? 'finishedCircle' : 'unfinishedCircle'"
                                            class="mx-5">
                                            1
                                            <span :class="basicInfo ? 'finishedCircleText' : 'unfinishedCircleText'">
                                                Basic Info
                                            </span>
                                        </span>
                                        <span class="unfinishedDash"></span>
                                        <span
                                            :class="permissionProduct ? 'selectedCircle' :  'unfinishedCircle'"
                                            class="mx-5">
                                            2
                                            <span :class="permissionProduct ? 'finishedCircleText' : 'unfinishedCircleText'">
                                                Products Permission
                                            </span>
                                        </span> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="api_call_in_progress">
            <div class="d-flex align-items-center justify-content-center">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
            </div>
        </template>
        <template v-else>
            <div :class="{'d-none':!basicInfo}">
                <base-info @switchWizard="switchWizard" :comp_data="comp_data_employee"></base-info>
            </div>
            <div :class="{'d-none':!permissionProduct}">
                <permission-product @switchWizard1="switchWizard1" :comp_data="comp_data_employee"></permission-product>
            </div>
        </template>
        
    </div>
    
</template>

<script>
    import PermissionProduct from "./ProductPermission";
    import BaseInfo from './BaseInformation.vue';
    import companies from '../mixins/companies';
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    export default {
        mixins: [companies],
        data() {
            return {
                basicInfo:true,
                api_call_in_progress: false,
                permissionProduct:false,
                basicInformationTab: false,
                permissionProductTab : false,
                comp_data_employee: null,
            }
        },
        // beforeRouteLeave (to, from, next) {
        //     // if (this.edit_enable == true) {
        //         let message = "<div class='d-block'>Unsaved Employee Basic Information Data would be lost, Do you want to proceed?</div>";
        //         let options = {
        //             html: true,
        //             loader: false,
        //             reverse: false, // switch the button positions (left to right, and vise versa)
        //             okText: 'Yes',
        //             cancelText: 'No',
        //             animation: 'fade',
        //             type: 'basic',
        //             verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
        //             verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
        //             backdropClose: true,
        //         };
        //         this.$dialog.confirm(message, options)
        //         .then(function () {
        //             next();
        //         })
        //         .catch(function () {
        //             next(false);
        //         });
        //     // } else {
        //     //     next()
        //     // }
        // },
        methods: {
            goToListView() {
                this.$router.push('/employee/listemployee/cmpid/'+ this.$route.params.company_id);
            },
            switchWizard(){
                this.permissionProduct = true
                this.basicInfo = false
                this.permissionProductTab = true
                this.basicInformationTab = false
            },
            switchWizard1(){
                this.permissionProduct = false
                this.basicInfo = true
                this.permissionProductTab = false
                this.basicInformationTab = true
            },
            async getCompData() {
                try {
                    this.api_call_in_progress = true;
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if (response.status_id == 1) {
                        let res_data = response.response
                        this.comp_data_employee = res_data
                    }
                    this.api_call_in_progress = false;
                }
                catch(err) {
                    // console.log(err)
                    this.api_call_in_progress = false;
                }
            },
        },
        mounted() {
            this.getCompData()
            if(this.loggedInUser.has_organization_or_standalone) {
                if(this.loggedInUser.user_role == 'employee') {
                    this.$router.push('/company/listview');
                }
            } else {
                this.$router.push('/company/createcompanyororganization');
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser;
            }
        },
        components: {
            PermissionProduct,
            BaseInfo,
            HalfCircleSpinner
        },
    }
</script>
<style>
    .main-div {
        background: #fff;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .create-company-container {
        background-color: #fff;
        /* padding: 15.5px 0 0px 0; */
        border-radius: 6px;
    }
    .overflow-auto {
        overflow: auto;
    }
    .unfinishedCircle {
        background: #e8e8e8;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.32);
        font-weight: 500;
    }

    .finishedCircle {
        background: #128807;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .selectedCircle {
        background: #ff9a06;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .finishedCircleText {
        position: absolute;
        font-size: 14px;
        color: #303031;
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedCircleText {
        position: absolute;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.32);
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedDash {
        color: #e8e8e8;
        font-size: 20px;
        border-top: 2px dashed rgba(0, 0, 0, 0.32);
        width: 25%
    }

    .finishedDash {
        color: #128807;
        font-size: 20px;
        border: 1px dashed #128807;
        width: 100px;
    }

    .mx-input {
        display: inline-block;
        height: 0px !important;
    }
</style>